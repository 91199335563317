import React, { useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import MenuHamburgerButton from './MenuHamburgerButton';
import { useTheme } from '../../../context/ThemeContext';
import HeaderAccountDropdown from './AccountDropdown';
import ChangePasswordModal from '../../../container/authorization/change-password-modal';
import { Link, useLocation } from 'react-router-dom';
import LogoXarisImage from '../../../images/logo-xaris.png';
import CustomerSelect from './CustomerSelect';
import ToggleTheme from '../../button/ToggleTheme';

function Header({ setMenuOpen, menuOpen }) {
    const themeState = useTheme();
    const [showChangePasswordModal, setShowChangePassword] = useState(false);
    let location = useLocation();

    return (
        <>
            <StyledHeader>
                <HeaderLeftSide>
                    <StyledLogoImage src={LogoXarisImage} />
                    <StyledName>
                        Customer<StyledNameBold>info</StyledNameBold> 2.1.4
                    </StyledName>
                    <MenuHamburgerButton
                        setMenuOpen={setMenuOpen}
                        menuOpen={menuOpen}
                    />
                </HeaderLeftSide>
                <HeaderLinks>
                    <StyledLink
                        to={'/'}
                        active={location.pathname === '/' ? 1 : 0}
                    >
                        Dashboard
                    </StyledLink>
                    <CustomerSelect />
                    <StyledLink
                        to={'/gebruikers'}
                        active={location.pathname === '/gebruikers' ? 1 : 0}
                    >
                        Gebruikers
                    </StyledLink>
                </HeaderLinks>
                <HeaderLinksMobile>
                    <CustomerSelect />
                </HeaderLinksMobile>
                <HeaderRightSide>
                    <ToggleTheme
                        toggled={themeState.dark ? true : false}
                        onClick={() => themeState.toggle()}
                    />
                    <HeaderAccountDropdown
                        openChangePasswordModal={() =>
                            setShowChangePassword(true)
                        }
                    />
                </HeaderRightSide>
            </StyledHeader>
            {showChangePasswordModal ? (
                <ChangePasswordModal
                    closeChangePasswordModal={() =>
                        setShowChangePassword(false)
                    }
                />
            ) : null}
        </>
    );
}

export default Header;

/* --- START STYLING --- */
const StyledHeader = styled.header`
    grid-area: header;
    background-color: ${props => props.theme.colors.header.background};
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12), 0 0 0 1px rgba(0, 0, 0, 0.08);
    z-index: 10;
`;

const HeaderLinks = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 40rem;
    color: ${props => props.theme.colors.header.textColor};

    @media only screen and (max-width: 1050px) {
        display: none;
    }
`;

const HeaderLinksMobile = styled.div`
    display: none;

    @media only screen and (max-width: 1050px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 40rem;
        color: ${props => props.theme.colors.header.textColor};
    }
`;

const StyledLink = styled(Link)`
    text-decoration: none;
    color: inherit;
    text-transform: uppercase;
    font-size: 1.3rem;
    border-bottom: 2px solid ${props => props.theme.colors.header.background};

    &:hover,
    :active {
        text-decoration: none;
        color: ${props => props.theme.colors.primary};
        border-bottom: 2px solid ${props => props.theme.colors.primary};
    }

    ${props =>
        props.active &&
        css`
            color: ${props.theme.colors.primary};
            border-bottom: 2px solid ${props.theme.colors.primary};
        `}
`;

const HeaderRightSide = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: ${props => props.theme.colors.white};
`;

const HeaderLeftSide = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const StyledLogoImage = styled.img`
    max-height: 3rem;

    @media only screen and (max-width: 80rem) {
        display: none;
    }
`;

const StyledName = styled.h2`
    margin-left: 1rem;
    display: inline-flex;
    justify-content: center;
    font-size: 1.7rem;
    font-weight: 400;
    font-family: 'Nanum Gothic', 'Lato', sans-serif;
    text-transform: uppercase;
    color: ${props => props.theme.colors.white};

    @media only screen and (max-width: 80rem) {
        display: none;
    }
`;

const StyledNameBold = styled.span`
    font-weight: 800;
`;

// const StyledNotification = styled.div`
//     width: 32px;
//     height: 30px;
//     margin: 0 8px;
// `;

// const StyledNotificationIcon = styled(IoIosNotificationsOutline)`
//     vertical-align: -10px;
// `;
//
// const StyledNotificationCount = styled.span`
//     position: relative;
//     background: ${props => props.theme.colors.primary};
//     border-radius: 4px;
//     padding: 1px 4px;
//     font-size: 1.2rem;
//     top: -6px;
//     right: 8px;
// `;
/* --- END STYLING --- */
